<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input
        label="Nome:"
        hint="Grupo ou cargo do usuário"
        v-model="newGroup.name"
      >
      </base-input>

      <el-collapse>
        <el-collapse-item title="Permissões">
          <el-row
            v-for="a in systemActions"
            :key="a.uid"
            type="flex"
            justify="space-between"
          >
            <h4>{{ a.title }}</h4>
            <el-switch
              @change="switchPermission(a, $event)"
              :value="hasPermission(a)"
            >
            </el-switch>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="saveGroup"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["group", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      newGroup: this.group || { name: "Novo usuário" },
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    group(v) {
      this.newGroup = v;
    },
  },
  computed: {
    ModalTitle() {
      return "uid" in (this.newGroup || {})
        ? "Editar grupo de usuários"
        : "Cadastrar novo grupo";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  created() {
    this.fetchSystemActions();
  },
  methods: {
    switchPermission(action, event) {
      if (!Array.isArray(this.newGroup.permissions))
        this.newGroup.permissions = [];
      if (event) this.newGroup.permissions.push(action);
      else
        this.newGroup.permissions = this.newGroup.permissions.filter(
          (p) => p.slug != action.slug
        );
    },
    hasPermission(p) {
      return (
        this?.newGroup?.permissions?.some((_) => p.slug === _.slug) || false
      );
    },
    selectGroup(v) {
      this.newGroup.group = v;
    },
    saveGroup() {
      const groupExists = this.newGroup && "uid" in this.newGroup;

      if (!groupExists)
        this.newGroup["password_confirmation"] = this.newGroup["password"];

      this.isLoadingSave = true;
      fetch(
        `${this.$store.state.apiUrl}groups${
          groupExists ? `/${this.newGroup.uid}` : ""
        }`,
        {
          credentials: "include",
          method: groupExists ? "PUT" : "POST",
          body: JSON.stringify(this.newGroup),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Grupo ${
                groupExists ? "alterado" : "cadastrado"
              } com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Erro ao salvar a operação",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    async fetchSystemActions() {
      fetch(`${this.$store.state.apiUrl}system/actions`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status == 200) return response.json();
          throw response.json();
        })
        .then((json) => (this.systemActions = json))
        .catch(() => (this.systemActions = []));
    },
  },
  name: "UserGroupModal",
};
</script>
<style scoped>
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>