<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Grupos de Usuário e Permissões</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openUserGroupModal({})"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="groups"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="created_at" label="criado em"> </el-table-column>
      <el-table-column prop="users.length" label="usuários"> </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="group">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openUserGroupModal(group.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteUserGroup(group.row)"
                title="
              Remover grupo de usuário?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <user-group-modal
      :showModal="showGroupModal"
      :group="group"
      @close-modal="showGroupModal = false"
      @should-update="fetchUserGroups"
    ></user-group-modal>
  </el-card>
</template>

<script>
import UserGroupModal from "../views/modals/UserGroup.vue";
export default {
  name: "UserGroups",
  components: { UserGroupModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    systemActions: [],
    groups: null,
    group: null,
    showGroupModal: false,
  }),
  mounted() {
    this.fetchUserGroups();
  },
  methods: {
    deleteUserGroup(group) {
      fetch(`${this.$store.state.apiUrl}groups/${group.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status === 200) return response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.fetchUserGroups();
            this.$notify({
              title: "Grupo de usuário removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          },
          (e) => {
            this.$notify({
              title: "Ocorreu um erro",
              type: "error",
              message:
                e?.message ||
                "Não foi possível remover o grupo, talvez você não tenha permissões suficientes",
              position: "bottom-right",
            });
          }
        );
    },
    openUserGroupModal(e) {
      (this.group = e), (this.showGroupModal = true);
    },
    fetchUserGroups() {
      fetch(`${this.$store.state.apiUrl}groups`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.groups = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    fetchSystemActions() {
      fetch(`${this.$store.state.apiUrl}/system/actions`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.systemActions = json))
        .catch(() => (this.hasError = true));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>